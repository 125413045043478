export const shiftLandCoveringVisuals = [
  {
    name: "Demo",
    markdown: `![Shift land covering GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const shiftLandCovering = {
  order: 26,
  name: "Shift land coverings left or right",
  subcategory: "Properties",
  markdown: `# Shift land coverings left or right

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  When a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} includes multiple {% inlineRouterLink articleId="add-a-land-covering" %}land coverings{% /inlineRouterLink %} on a single {% inlineRouterLink articleId="add-a-fee-parcel" %}fee parcel{% /inlineRouterLink %}, you may want to display them in a certain horizontal order. To do this, you can move a land covering left or right one place at a time.

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land coverings" %}land covering{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="shiftLandCoveringLeft" /%} or {% inlinePropertyDiagramIcon name="shiftLandCoveringRight" /%}.
  4. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. The land covering moves one place in the selected direction.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$shiftLandCoveringVisuals /%}
  `,
};
